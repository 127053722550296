import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../../../components/layout'
import { useStories } from '../../../../hooks/useStories'
import { useCategories } from '../../../../hooks/useCategories'
import { Stories } from '../../../../components/stories'
import { Article } from '../../../../components/article'
import { Story as StoryType } from '../../../../types/story'
import { Category as CategoryType } from '../../../../types/category'
import { SEO } from '../../../../components/seo'

const StoryPage = ({ params }: any) => {
	const { stories } = useStories()
	const [story, setStory] = useState<StoryType | null>(null)
	const { categories } = useCategories()
	const [category, setCategory] = useState<CategoryType | null>(null)

	useEffect(() => {
		const story = stories.find(story => story.title === params._title)
		if (story) {
			setStory(story)
		}
	}, [params, stories])

	useEffect(() => {
		const category = categories.find(category => category.title === params._category)
		if (category) {
			setCategory(category)
		}
	}, [params, categories])

	return (
		<Layout>
			<SEO
				title={`${params._title} > ${params._category} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<div className="story">
				{story && category && (
					<>
						<Article type="story" article={story} category={category} />
						<Stories />
					</>
				)}
			</div>
		</Layout>
	)
}

export default StoryPage
